import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import * as React from "react"

const PosterEntry = ({ poster }) => {
    const [open, setOpen] = React.useState(false)

    return (
        <div>
            <a href="#" onClick={(event) => {
                event.preventDefault()
                setOpen(true)
            }}>
                <div className="panel panel-default">
                    <div className="panel-body text-black">
                        <h3 className="title">{poster.title}</h3>
                        <div className="row">
                            <div className="col-xs-6">
                                <small className="text-muted">Poster ID</small>
                                <p className="poster-id">{poster.id}</p>
                            </div>
                            <div className="col-xs-6">
                                <small className="text-muted">Session</small>
                                <p><span className={`badge session-${poster.session}`}>{poster.session}</span></p>
                            </div>
                        </div>
                        <small className="text-muted">Authors</small>
                        <p className="authors">{poster.authors.join(", ")}</p>
                        <small className="text-muted">Abstract</small>
                        <p className="abstract">{poster.abstract}</p>
                        {poster.doi ? (
                            <div>
                                <small className="text-muted">DOI</small>
                                <p className="authors">{poster.doi}</p>
                            </div>
                        ) : null}
                    </div>
                </div>
            </a>

            <Modal open={open} onClose={() => setOpen(false)} center>
                <h2 className="modal-title">{poster.title}</h2>
                <small className="text-muted">Poster ID</small>
                <p>{poster.id}</p>
                <small className="text-muted">Session</small>
                <p><span className={`badge session-${poster.session}`}>{poster.session}</span></p>
                <small className="text-muted">Authors</small>
                <p>{poster.authors.join(", ")}</p>
                <small className="text-muted">Abstract</small>
                <p>{poster.abstract}</p>
                {poster.doi ? (
                    <div>
                        <small className="text-muted">DOI</small>
                        <p>{poster.doi}</p>
                    </div>
                ) : null}
            </Modal>
        </div>
    )
}

export default PosterEntry
